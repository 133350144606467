import {useState} from 'react'

import config from 'app/config'

export default function useToken() {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const data = JSON.parse(decodeUnicode(window.localStorage.getItem(config.TOKEN_NAME).split('.')[1]))
            if (data.exp < Date.now() / 1000) throw new Error('token expired')
            return data
        } catch (e) {
            console.error(e)
            if (window.location.pathname !== '/auth') window.location = `/auth`
        }
        return undefined
    })

    const setValue = value => {
        // Save state
        setStoredValue(value)
        // Save to local storage
        window.localStorage.setItem(config.TOKEN_NAME, value)
    }

    return [storedValue, setValue]
}

const decodeUnicode = str => {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
        atob(str)
            .split('')
            .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''),
    )
}
