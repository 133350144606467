import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'

import * as serviceWorker from './serviceWorker'

import config from 'app/config'

import {ApolloClient} from 'apollo-client'
import {ApolloLink} from 'apollo-link'
import {onError} from 'apollo-link-error'
import {ApolloProvider} from '@apollo/react-hooks'
import {setContext} from 'apollo-link-context'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {createUploadLink} from 'apollo-upload-client'

// GraphQL
const authLink = setContext((_, {headers}) => {
    const token = localStorage.getItem(config.TOKEN_NAME)
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    }
})

const httpLink = authLink.concat(createUploadLink({uri: `${config.API_URL}query`}))

const cache = new InMemoryCache()

export const apolloClient = new ApolloClient({
    link: ApolloLink.from([
        onError(({graphQLErrors, networkError}) => {
            if (graphQLErrors) graphQLErrors.map(({message, locations, path}) => console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))
            if (networkError) console.error(`[Network error]: ${networkError}`)
            // console.debug(graphQLErrors, networkError, operation, response)
        }),
        httpLink,
    ]),
    cache,
    connectToDevTools: true,
})

ReactDOM.render(
    <ApolloProvider client={apolloClient}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </ApolloProvider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
