import React, {Suspense, useEffect} from 'react'
import {useRoutes, useHash} from 'raviger'

import useToken from 'app/hooks/useToken'

const AppInternal = React.lazy(() => import('./AppInternal'))
const Auth = React.lazy(() => import('app/pages/auth/Auth'))

function App() {
    const hash = useHash({stripHash: true})
    const [, saveToken] = useToken()

    useEffect(() => {
        if (hash.length > 0) {
            saveToken(hash)
            window.location.replace('/')
        }
    }, [hash, saveToken])

    const routeResult = useRoutes({
        '/auth': () => <Auth />,
        '/*': () => <AppInternal />,
    })

    return <Suspense fallback={<div />}>{routeResult}</Suspense>
}

export default App
